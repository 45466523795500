import React, { Component } from 'react'
import { Link } from 'gatsby'
import MyImage from '../components/myImage.js'

class ProjectCard extends Component {
  render (){
    const {
      title,
      desc,
      link,
      hero,
    } = this.props

    return (
      <div className="project-card-wrapper">
        <div className="project-card" >

            <div className="project-deets">
              <Link to={link}><h2 className="title">{title}</h2></Link>
              <p className="desc">{desc}</p>
            </div>
            <Link to={link}><div className="project-hero"><MyImage filename={hero} /></div></Link>
        </div>
      </div>
    )
  }
}

export default ProjectCard
