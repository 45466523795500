import React from 'react'
import ProjectCard from "../components/projectCard"
import projectList from "../data/projects"
//import { Link } from 'gatsby'

import Layout from '../components/layout'
//import Hero from '../components/hero'

const Whoami = () => (
  <Layout>
    <div className="container">
      <div className="whatdo">
        <h1>I am a UX designer specializing in systems design for healthcare</h1>
      </div>
      <div className="project-list list">
        {projectList.map((project, i) => {
          return (
            <ProjectCard
              link={project.link}
              desc={project.desc}
              hero={project.hero}
              title={project.title}>
            </ProjectCard>
          )
        })}
      </div>
    </div>

  </Layout>
)

export default Whoami
